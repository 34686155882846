export const IconLink = {
  us: 'https://forms.gle/SaSo4TE3ptAQxgVP6',
  dashboard: 'https://app.archloot.com',
  dashboardMarket: 'https://app.archloot.com/market',
  twitter: 'https://twitter.com/archlootOS',
  telegram: 'https://t.me/archloot',
  discord: 'https://discord.com/invite/eRq9GZANng',
  medium: 'https://medium.com/archloot',
  reddit: 'https://www.reddit.com/r/ArchLoot',
  docs: 'https://doc.archloot.com',
  faq:'https://doc.archloot.com/faqs',
  channel: 'https://www.youtube.com/channel/UClhi-ayUXvva1Ck0dJHC4Iw',
  instruction:'https://medium.com/archloot/master-island-your-first-zone-of-survival-bb08326d9da4',
  mediaKit:'https://archloot.com/static/Archloot-media-assets.zip',
  privacyPolicy:'https://archloot.com/static/Archloot%20documents%20-%20Privacy%20Policy%2C%20Code%20of%20Conduct%2C%20Term%20of%20Use.pdf',
  githubConnexionContract:'https://github.com/Connector-Gamefi/ConnexionContract',
  apk:'https://archloot.com/static/m/ArchLoot2.27.apk',
  connexion:'https://connexion.games',
  ios:'https://apps.apple.com/us/app/archloot-game/id6447827897',
  archLootApk:'https://archloot.com/static/m/ArchLoot.apk',
  android:'https://play.google.com/store/apps/details?id=com.connexion.loot',
  miniApp:'https://t.me/Archlootbot/miniapp',
  vote:'https://vote.archloot.com/',
  forum:'https://forum.archloot.com/',
  duckitfun_bot:'https://t.me/Duckitfun_bot/app'
};
